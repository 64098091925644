module.exports = {
  ENV: 'prod',
  APP_NAME: 'sem',
  END_POINT: 'https://ssm.services.apihf.com',
  API_KEY: 'c09CctEY2UrY7zEBFKkFQKnY3yXUr98w23PTryi0',
  BRAND_KEY: '77c21655-0d82-4cf4-9de1-2e4ee6fc13b3',
  CLIENT_ID: '2hcnhauq2qob6f67q6ikcb7of1',
  USER_POOL_ID: 'eu-west-1_HIn9p5IYR',
  HEADER_MESSAGE_SIZE: 41,
  AGENTS_GROUP: 'SEM_AGENTS',
}